import moment from 'moment';

/* eslint-disable*/
import {
  PORTABLE_UNIT_FREQUENCY,
  PREFERS_CONTACT_BY,
  RECAPTCHA_CALLMEBACK, RECAPTCHA_CHAT,
  RECAPTCHA_CONTACT_US,
  RECAPTCHA_SMALL_BUSINESS,
  RECAPTCHA_TEAM_CANADA,
  UNIT_FREQUENCY,
} from "@/utils/constants";

/* Contact US form */
export const makeRequest = (
  formData: any,
  token: any,
  path: any,
  inquiry: any,
  existing: any,
  partnership: any,
  job: any,
  locale: any = "fr",
  facilityId: any = "L002",
  previousRoute: any = "https://accessstorage.ca/en/"
) => {
  const invendr = typeof window !== "undefined" && window.invendrData;
  return {
    email: formData?.email,
    newInquiry: inquiry,
    existingClient: existing,
    partnershipInquiry: partnership,
    jobInquiry: job,
    fullName: formData?.fullName,
    formURL: path,
    previousUrl: previousRoute,
    invendrDetails: {
      callpotentialLeadSource: invendr?.active_rule || "",
      mediaNumber: (document.querySelector('.headerPhone') as HTMLElement)?.innerText || "",
      invendrSource: invendr?.source || "",
      invendrCampaign: invendr?.campaign || "",
      invendrMedium: invendr?.medium || "",
      invendrContent: invendr?.content || "",
      invendrTerm: invendr?.term || "",
    },
    message: formData?.description,
    phone: formData?.phone,
    preferredLanguage: locale,
    reCaptchaAction: RECAPTCHA_CONTACT_US,
    reCaptchaToken: token,
    facilityId,
  };
};

/* Small Business form */
export const makeSmallBusinessRequest = (
  data: any,
  token: any,
  path: any,
  locale: any = "fr",
  facilityId: any = "L002",
) => {
  const invendr = typeof window !== "undefined" && window.invendrData;
  return {
    email: data?.email,
    fullName: data?.fullName,
    formURL: path,
    invendrDetails: {
      callpotentialLeadSource: invendr?.active_rule || "",
      mediaNumber: (document.querySelector('.headerPhone') as HTMLElement)?.innerText || "",
      invendrSource: invendr?.source || "",
      invendrCampaign: invendr?.campaign || "",
      invendrMedium: invendr?.medium || "",
      invendrContent: invendr?.content || "",
      invendrTerm: invendr?.term || "",
    },
    inquiryType: data?.inquiryType,
    company: data?.company,
    phone: data?.phone,
    preferredLanguage: locale,
    prefersContactBy: data?.prefer,
    reCaptchaAction: RECAPTCHA_SMALL_BUSINESS,
    reCaptchaToken: token,
    facilityId,
  };
};

/* ValetService form */
export const makeValetServiceRequest = (
  data: any,
  token: any,
  path: any,
  unitDimensions: any,
  unitRate: any,
  locale: any = "fr"
) => {
  const invendr = typeof window !== "undefined" && window.invendrData;
  return {
    dateNeeded: data?.date || moment(new Date()).format('YYYY-MM-DD'),
    email: data?.email,
    fullName: data?.fullName,
    formURL: path,
    invendrDetails: {
      callpotentialLeadSource: invendr?.active_rule || "",
      mediaNumber: (document.querySelector('.headerPhone') as HTMLElement)?.innerText || "",
      invendrSource: invendr?.source || "",
      invendrCampaign: invendr?.campaign || "",
      invendrMedium: invendr?.medium || "",
      invendrContent: invendr?.content || "",
      invendrTerm: invendr?.term || "",
    },
    message: data?.description,
    phone: data?.phone,
    preferredLanguage: locale,
    prefersContactBy: PREFERS_CONTACT_BY,
    reCaptchaAction: RECAPTCHA_CALLMEBACK,
    reCaptchaToken: token,
    unitDimensions: unitDimensions || "",
    unitRate: unitRate || "",
    unitRateFrequency: UNIT_FREQUENCY,
  };
};
export const makeCallBackRequest = (
  data: any,
  token: any,
  path: any,
  facilityId: any,
  unitRate: any,
  unitDimensions: any,
  ero: any,
  locale: any = "fr",
  unitTypeId: any,
  PromotionSLName: any,
  promotionDescription: any,
  consessionId: any,
  unitWidth: any,
  unitLength: any,
  storeType: any ="ss",
  action: any = RECAPTCHA_CALLMEBACK,
) => {
  const invendr = typeof window !== "undefined" && window.invendrData;
  return {
    ero,
    dateNeeded: data?.date || moment(new Date()).format('YYYY-MM-DD'),
    email: data?.email,
    fullName: data?.fullName,
    formURL: path,
    invendrDetails: {
      callpotentialLeadSource: invendr?.active_rule || "",
      mediaNumber: (document.querySelector('.headerPhone') as HTMLElement)?.innerText || "",
      invendrSource: invendr?.source || "",
      invendrCampaign: invendr?.campaign || "",
      invendrMedium: invendr?.medium || "",
      invendrContent: invendr?.content || "",
      invendrTerm: invendr?.term || "",
    },
    message: data?.description,
    phone: data?.phone,
    preferredLanguage: locale,
    prefersContactBy: PREFERS_CONTACT_BY,
    reCaptchaAction: action,
    reCaptchaToken: token,
    unitDimensions,
    unitRate: unitRate || "",
    unitRateFrequency: storeType === "ps" ? PORTABLE_UNIT_FREQUENCY: UNIT_FREQUENCY
    ,
    facilityId,
    unitTypeId,
    PromotionSLName,
    promotionDescription,
    consessionId,
    unitWidth,
    unitLength,
  };
};

export const makeTeamCanadaRequest = (
  data: any,
  token: any,
  path: any,
  locale: any = "fr",
) => {
  const invendr = typeof window !== "undefined" && window.invendrData;
  return {
    dateNeeded: data?.date,
    email: data?.email,
    fullName: data?.fullName,
    formURL: path,
    invendrDetails: {
      callpotentialLeadSource: invendr?.active_rule || "",
      mediaNumber: (document.querySelector('.headerPhone') as HTMLElement)?.innerText || "",
      invendrSource: invendr?.source || "",
      invendrCampaign: invendr?.campaign || "",
      invendrMedium: invendr?.medium || "",
      invendrContent: invendr?.content || "",
      invendrTerm: invendr?.term || "",
    },
    message: data?.description,
    phone: data?.phone,
    preferredLanguage: locale,
    prefersContactBy: data?.prefer,
    reCaptchaAction: RECAPTCHA_TEAM_CANADA,
    reCaptchaToken: token,
    unitRateFrequency: UNIT_FREQUENCY,
    organization: data?.national,
    status: data?.status,
    province: data?.province,
  };
};

/* Chat callback */
export const makeChatRequest = (
    formData: any,
    token: any,
    path: any,
    locale: any = "fr",
) => {
  const invendr = typeof window !== "undefined" && window.invendrData;
  return {
    convId: formData.convId,
    email: formData?.email,
    fullName: formData?.fullName,
    notes: formData?.notes,
    formURL: path,
    invendrDetails: {
      callpotentialLeadSource: invendr?.active_rule || "",
      mediaNumber: (document.querySelector('.headerPhone') as HTMLElement)?.innerText || "",
      invendrSource: invendr?.source || "",
      invendrCampaign: invendr?.campaign || "",
      invendrMedium: invendr?.medium || "",
      invendrContent: invendr?.content || "",
      invendrTerm: invendr?.term || "",
    },
    phone: formData?.phone,
    lcode: formData?.lcode,
    preferredLanguage: locale,
    reCaptchaAction: RECAPTCHA_CHAT,
    reCaptchaToken: token,
  };
};

/* Referral form */
export const makeReferralRequest = (
  data: any,
  token: any,
) => {
  return {
    fullName: data?.fullName,
    email: data?.email,
    phone: data?.phone,
    brand: data?.brand || "as",
    lcode: data?.lcode,
    reCaptchaToken: token,
  };
};
